<template lang="html">
  	<Card v-bind="$props" class="ui-shortcutcard" @click="goToRoute">
		<fa-icon class="icon" :icon="['fal', 'server']"/>
		<span class="title">Librairie de services</span>
  	</Card>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { inject, onMounted, ref, reactive, computed, watch } from "vue";
export default {
	props: {
		size: {
			type: String,
			default: "xtra-small"
		},
		type: {
			type: String,
			default: "complex"
		},
		route:{
			type: String
		}
	},
	setup (props) {
		const router = useRouter()
	    const route = useRoute()

		const goToRoute = function () {
			var route = router.getRouteByName(props.route)
			console.log(route);

			//Checking if route is not an empty object
			if (route.path) {
				router.push(route.path);
			}
		}

		return {
			goToRoute
		};

	}
}
</script>

<style lang="scss" scoped>

.ui-shortcutcard
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	position: relative;

	&:before {
           content:'';
           position:absolute;
           width:100%;
           height:100%;
           top:0;
           left:0;
		   mix-blend-mode:lighten;

		   transition: all 0.2s ease;
	   }

	&:hover::before
	{
		background:rgba(255,255,255,0.1) /* black mask with 5% opacity */
	}

	.icon
	{
		margin: 4px;
		font-size: 32px;
	}
}
</style>
