<template lang="html">
	<div class="page" id="page-settings">
  		<PageHeader :title="$route.name"/>

		Cette page est vide pour l'instant, et se remplira au gré des mises a jour.

		<br>

		<KebabMenu>
			<KebabMenuTitle title="Versions"/>
			<KebabMenuItem :icon="['far','trash-alt']" title="Delete Version"/>
			<KebabMenuItem :icon="['far','pen']" title="Edit Version"/>
			<KebabMenuItem :icon="['far','plus']" title="Add Version"/>
			<KebabMenuSeparator />
			<KebabMenuTitle title="routes"/>
			<KebabMenuItem :icon="['far','file']" title="New Route"/>
			<KebabMenuItem :icon="['far','empty-set']" title="Clear all routes"/>
		</KebabMenu>
		<KebabMenu x-direction="left" y-direction="up">
			<KebabMenuTitle title="Versions"/>
			<KebabMenuItem :icon="['far','trash-alt']" title="Delete Version"/>
			<KebabMenuItem :icon="['far','pen']" title="Edit Version"/>
			<KebabMenuItem :icon="['far','plus']" title="Add Version"/>
			<KebabMenuSeparator />
			<KebabMenuTitle title="routes"/>
			<KebabMenuItem :icon="['far','file']" title="New Route"/>
			<KebabMenuItem :icon="['far','empty-set']" title="Clear all routes"/>
		</KebabMenu>
  	</div>

</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
