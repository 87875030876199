<template lang="html">
	<div class="popin-wrapper" v-if="isVisible">
		<slot name="default">

		</slot>
	</div>
</template>

<script>
export default {
	props: {
		isVisible: {
			type: Boolean
		}
	}
}
</script>

<style lang="scss" scoped>
.popin-wrapper
{
	position: absolute;
	background-color: rgba(11, 11, 11, 0.81);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;

}
</style>
