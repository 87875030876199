<template lang="html">
  	<div 
      class="ui-input" 
      @click="$refs.input.focus()" 
      :class="inputClasses" 
      ref="ui-input"
    >
        <span v-if="label" class="label">{{label}}</span>
        
        <input
            class="field"
            ref="input"
            v-if="type == 'text'"
            :placeholder="placeholder"
            type="text"
            name=""
            v-model="value"
            @input='updateValue'
        >
        <textarea
            class="field area"
            ref="input"
            name="name"
            v-if="type == 'textarea'"
            @input='updateValue'
            :placeholder="placeholder"
            v-model="value"
        >
        </textarea>
  	</div>
</template>

<script>
export default {
	name: "Input",
	data () {
		return {
			inputClasses : {}
		};
	},
	props: {
		label: {
			type: String,
		},
        type: {
			type: String,
            default: "text"
		},
        value: {
            type: String
        },
        font: {
            type: String
        },
        length: {
            type: String,
            default: "default"
        },
        size: {
            type: String,
            default: "default"
        },
        placeholder: {
            type: String
        }
	},
    methods: {
        updateValue () {
            this.$emit("update:value", this.value)
        }
    },
	mounted () {
        this.inputClasses[`length-${this.length}`] = true;
        this.inputClasses[`size-${this.size}`] = true;
		this.inputClasses[`font-${this.font}`] = true;
	}
}
</script>

<style lang="scss" scoped>

.ui-input
{
    display: flex;
    flex-direction: column;
    width: 256px;
    box-sizing: border-box;

    .label
    {
        margin-bottom: 4px;
        margin-left: 4px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.85em;
        color: $FG_SECONDARY;
        user-select: none;
    }

    &+&
    {
        margin-top: 16px;
    }

	.field
	{

        transition: all 0.2s ease;

        border: 1px solid $BG_TERTIARY;

        display: inline-block;
    	background-color: $BG_SECONDARY;
    	border-radius: 8px;
    	color: $FG_MAIN;
    	padding: 8px;
        font-size: 18px;
    	align-items: center;

    	cursor:text;
		outline: none;
		color: $FG_MAIN;

        &.area
        {
            max-width: 512px;
            width: 512px;
            box-sizing: border-box;
            padding: 16px;
        }

        &::placeholder
        {
            font-weight: 300;
            font-style: italic;
            font-size: 18px;
        }

        &:focus
        {
            background-color: lighten($BG_SECONDARY, 5%);
        }
	}

    &.length-
    {
        &long
        {
            width: 512px;
        }
        &small
        {
            width: 128px;
        }
        &fill
        {
            width: 100%;
        }
    }

    &.font-
    {
        &mono
        {
            input
            {
                font-family: monospace;
                font-size: 14px;
            }
        }
    }
}

</style>
