import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Cookies from 'universal-cookie'
import sha256 from "crypto-js/sha256"
import * as d3 from 'd3';
const fs = require('fs');
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

const requireComps = require.context(
    './components/ui',
    true,
    /.*\.(vue|js)$/
);

library.add(fas)
library.add(far)
library.add(fal)
library.add(fab)
library.add(fad)

var app = createApp(App)
var cookies = new Cookies();
app.component("fa-icon", FontAwesomeIcon);
app.component("fa-layer", FontAwesomeLayers);
app.component('fa-layer-text', FontAwesomeLayersText);


const CONFIG = require('@/assets/config.js');

app.config.globalProperties.$cookies = cookies;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$env = process.env.NODE_ENV;
app.provide('axios', axios);
app.provide('d3', d3);
// app.provide('GATEWAY_URL', 'https://dev.backoffice.production-webadn.com:3011');
app.provide('GATEWAY_URL', CONFIG.SKYWAY.URL + ":" + CONFIG.SKYWAY.PORT);
app.provide('SERVICE_LIBRARY_URL', CONFIG.SERVICE_LIBRARY.URL + ":" + CONFIG.SERVICE_LIBRARY.PORT);
app.provide('NODE_ENV', process.env.NODE_ENV);

// Chargement des components UI globalement sur tout le projet
requireComps.keys().forEach((item) => {
    const compConfig = requireComps(item);
    const compName = item
        .split("/")
        .pop()
        .replace(/\.(vue|js)/, "");

    app.component(
        compName,
        compConfig.default || compConfig
    )
});


/**
* Retourne une route correspondant au nom donné
* @author Charles Stieffenhofer <c.stieffenhofer@web-adn.com>
*/
router.getRouteByName = function(routeName) {
    for (var r of router.getRoutes()) {
        if (r.name === routeName) {
            return r;
        }
    }
    //default case: return an empty object
    return {};
}

router.cookies = cookies;
router.sha256 = sha256;
app.use(router);

app.mount('#app');
