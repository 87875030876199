<template>
	<div class="page">
		<div class="header">
			<PageHeader title="Projets"/>
		</div>
		<div class="main">
			<SearchInput
				:isLoading="isSearchbarLoading"
				v-model:value="filterText"
				placeholder="Rechercher un projet ..."
				@input="filterProjects"
			/>
			<div class="project-list">
				<ProjectCard
					v-for="project in projects"
					:key="project"
					:project="project"/>
			</div>
		</div>
	</div>
</template>

<script>
import { inject, onMounted, ref, reactive, computed, watch } from "vue";

export default {
	name: 'Projects',
	props: {},
	setup (props) {
		const axios = inject('axios');
		const GATEWAY_URL = inject("GATEWAY_URL")
		const projects = ref([]);
		const filterText = ref("");
		const filteredProjects = ref([]);
		const isSearchbarLoading = ref(false);
		const currentTimeout = ref("");
		const loadProjects = async function () {
			try {
				const results = await axios.get(`${GATEWAY_URL}/v1/projects`);
				projects.value = results.data;
			} catch(e) {
				projects.value = [];
				console.error(e);
			}
		}
		const filterProjects = async function () {
			if (currentTimeout.value) {
				clearTimeout(currentTimeout.value);
			}
			isSearchbarLoading.value = true;
			currentTimeout.value = setTimeout(async function () {
				const reg = new RegExp(`^.*${filterText.value}.*$`, "i")
				filteredProjects.value = projects.value.filter((el) => {
					return reg.test(el.name)
				});
				isSearchbarLoading.value = false;
			}, 300);
		}
		watch(projects, filterProjects);
		onMounted(async () => {
			await loadProjects();
		})
		return {
			projects,
			filterText,
			filteredProjects,
			isSearchbarLoading,
			loadProjects,
			filterProjects
		}
	}
}
</script>

<style lang="scss" scoped>
.main .ui-input {
	flex-direction: row-reverse;
	max-width: 100%;
	width: 400px;
	padding-left: 16px;
	margin-bottom: 32px;
}

.project-list {
	display: grid;
	grid-template-columns: 100%;
	grid-gap: 20px;
}

@media screen and (min-width: $TABLET_LANDSCAPE) {
	.project-list {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (min-width: $DESKTOP) {
	.project-list {
		grid-template-columns: repeat(3, 1fr);
	}
}
</style>
