<template>
	<div class="page">
		<Breadcrumbs/>
		<div class="hero">
			<div class="banner">
				<img src="https://picsum.photos/1920/200" alt="">
			</div>
			<div class="text">
				<div class="image">
					<img src="https://picsum.photos/200/200" alt="">
				</div>
				<h1>{{client.name}}</h1>
			</div>
		</div>
		<div class="main">
			<div class="projects">
				<div class="project-header">
					<h2 class="block-projects-title">
						Projets
						<Label size="large"> {{client.projects ? client.projects.length : 0}}</Label>
					</h2>
					<KebabMenu>
						<KebabMenuTitle title="Projets"/>
						<KebabMenuItem
							icon="plus"
							title="Nouveau Projet"
							@click="setProjectPopUpVisible(true)"
							/>
						<KebabMenuSeparator></KebabMenuSeparator>
						<KebabMenuTitle title="Client"/>
						<KebabMenuItem
							icon="wrench"
							title="Modifier"
							@click="setUpdateClientPopUpVisible(true)"
							/>
						<KebabMenuItem
							icon="trash"
							title="Suprimer"
							@click="setDeleteClientPopUpVisible(true)"
							/>
					</KebabMenu>
				</div>
				<div class="project-list">
					<ProjectCard
						v-for="project in client.projects"
						:key="project"
						:project="project"/>
				</div>
			</div>
			<!-- <section class="actions">
				<header>
					<h2>Opération sur le client</h2>
					<span class="subtitle">Ne pas toucher a moins que vous soyez sûr de ce que vous faites.</span>
				</header>
				<main>
					<Button
						color="yellow"
						icon="wrench"
						@click="setUpdateClientPopUpVisible(true)"
					>
						Modifier
					</Button>
					<Button
						color="red"
						icon="trash"
						@click="setDeleteClientPopUpVisible(true)"
					>
						Supprimer
					</Button>
				</main>
			</section>
-->
		</div>

		<PopUpWrapper :isVisible="newProjectFormShown">
			<PopUp :isVisible="newProjectFormShown">
				<template v-slot:header>
					<h2>Nouveau Projet</h2>
				</template>

				<template v-slot:content>
					<FormInput
						v-model:value="projectForm.project.name"
						label="Nom du Projet"
					/>
					<FormInput
						v-model:value="projectForm.project.tag"
						label="Tag du Projet"
					/>
					<InputColor
						@change="setProjectColor"
						label="Couleur du Projet"
						:value="webadnColor"
					/>
				</template>

				<template v-slot:actions>
					<Button color="blue" @click="createNewProject">
						Créer
					</Button>
					<Button
						type="tertiary"
						@click="setProjectPopUpVisible(false)"
					>
						Annuler
					</Button>
				</template>
			</PopUp>
		</PopUpWrapper>

		<PopUpWrapper :isVisible="updateClientFormShown">
			<PopUp :isVisible="updateClientFormShown">
				<template v-slot:header>
					<h2>Modifier le client</h2>
				</template>

				<template v-slot:content>
					<FormInput
						v-model:value="updateClientForm.client.name"
						label="Nom du Client"
					/>
				</template>

				<template v-slot:actions>
					<Button color="yellow" @click="updateClient">
						Mettre à jour
					</Button>
					<Button
						type="tertiary"
						@click="setUpdateClientPopUpVisible(false)"
					>
						Annuler
					</Button>
				</template>
			</PopUp>
		</PopUpWrapper>

		<PopUpWrapper :isVisible="deleteClientFormShown">
			<PopUp :isVisible="deleteClientFormShown">
				<template v-slot:header>
					<h2 class="warning title">Cette action est définitive.</h2>
				</template>

				<template v-slot:content>
					<p class="warning">
						Êtes-vous sûr de vouloir supprimer le client <strong>{{ client.name }}</strong> ?
					</p>
				</template>

				<template v-slot:actions>
					<Button color="red" @click="deleteClient">
						Supprimer
					</Button>
					<Button
						type="tertiary"
						@click="setDeleteClientPopUpVisible(false)"
					>
						Annuler
					</Button>
				</template>
			</PopUp>
		</PopUpWrapper>
	</div>
</template>

<script>
import { inject, ref, computed, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
	name: "Client",
	props: { inject, ref },
	setup(props, ctx) {
		const axios = inject("axios");
		const GATEWAY_URL = inject("GATEWAY_URL")
		const route = useRoute();
		const router = useRouter();
		const clientId = route.params.id;
		const client = ref([]);
		const newProjectFormShown = ref(false);
		const updateClientFormShown = ref(false);
		const deleteClientFormShown = ref(false);
		const webadnColor = ref("#f5c82d");

		/* FORMS */

		const projectForm = reactive({
			project: {
				clients: [
					{
						_id: clientId,
					},
				],
				name: "",
				tag: "",
				color: webadnColor.value,
			},
		});

		const updateClientForm = reactive({
			client: {
				name: client.name,
			},
		});

		/* AJAX */
		const loadClient = async function () {
			try {
				const response = await axios.get(
					`${GATEWAY_URL}/v1/clients/${clientId}?populate=projects`
				);
				client.value = response.data;
			} catch (e) {
				console.error(e);
				ctx.emit("toast", {
					content:
						"Le client n'a pas pu être trouvé, vérifiez votre réseau et réessayez.",
					color: "gd-red",
					icon: "exclamation-circle",
				});

				client.value = {
					name: "",
					projects: [],
				};
			}
		};

		const createNewProject = async function () {
			if (!testFormNewProject()) {
				return;
			}

			try {
				const response = await axios.post(
					`${GATEWAY_URL}/v1/projects`,
					{
						project: projectForm.project,
					}
				);
			} catch (e) {
				ctx.emit("toast", {
					content:
						"Une erreur est survenue lors de la création du project",
					color: "gd-red",
					icon: "exclamation-circle",
				});

				return;
			}

			loadClient();
			setProjectPopUpVisible(false);
			ctx.emit("toast", {
				content: "Un nouveau projet a été créé",
				color: "gd-green",
				icon: "check",
			});
		};

		const updateClient = async function () {

			if(!updateClientForm.client.name?.length > 0) {
				ctx.emit("toast", {
					content: "Veuillez renseigner un nom de client",
					color: "gd-red",
					icon: "exclamation-circle",
				});
				return;
			}

			try {
				const response = await axios.put(
					`${GATEWAY_URL}/v1/clients/${clientId}`,
					{
						client: updateClientForm.client,
					}
				);

			} catch (e) {
				ctx.emit("toast", {
					content: "Une erreur est survenue lors de la mise à jour du client",
					color: "gd-red",
					icon: "exclamation-circle",
				});

				return;
			}

			loadClient();
			setUpdateClientPopUpVisible(false);
			ctx.emit("toast", {
				content: "Le client a été mis à jour",
				color: "gd-green",
				icon: "check",
			});
		}

		const deleteClient = async function () {
			try {
				const response = await axios.delete(
					`${GATEWAY_URL}/v1/clients/${clientId}`
				);
			} catch (e) {
				ctx.emit("toast", {
					content: "Une erreur est survenue lors de la suppression du client",
					color: "gd-red",
					icon: "exclamation-circle",
				});

				return;
			}

			router.push("/clients");
			ctx.emit("toast", {
				content: "Le client a été supprimé",
				color: "gd-green",
				icon: "check",
			});
		}

		/* COMPUTED */
		const hasProjects = computed(() => client.value?.projects?.length > 0);

		/* METHODS */
		const setProjectPopUpVisible = function (isPopUpVisible) {
			newProjectFormShown.value = isPopUpVisible;
		};

		const setUpdateClientPopUpVisible = function (isPopUpVisible) {
			updateClientFormShown.value = isPopUpVisible;
		};

		const setDeleteClientPopUpVisible = function (isPopUpVisible) {
			deleteClientFormShown.value = isPopUpVisible;
		};


		const setProjectColor = function (color) {
			projectForm.project.color = color;
		};

		const testFormNewProject = function () {
			const errorsMsg = [];
			if (projectForm.project.name.length < 3) {
				errorsMsg.push(
					"Le nom du projet doit contenir au minimum 3 caractères"
				);
			}

			if (projectForm.project.tag.length < 2) {
				errorsMsg.push(
					"Le tag du projet doit contenir au minimum 2 caractères"
				);
			} else if (projectForm.project.tag.length > 8) {
				errorsMsg.push(
					"Le tag du projet ne doit pas contenir plus de 8 caractères"
				);
			}

			if (errorsMsg.length > 0) {
				ctx.emit("toast", {
					content:
						"<div>" + errorsMsg.join("</div><br><div>") + "</div>",
					color: "gd-red",
					icon: "warning",
				});

				return false;
			}

			return true;
		};

		const gotToProject = function (projectId) {
			router.push({ path: `/projects/${projectId}` });
		};

		/* INIT */
		onMounted(async () => {
			await loadClient();
		});

		return {
			client,
			hasProjects,
			newProjectFormShown,
			updateClientFormShown,
			deleteClientFormShown,
			projectForm,
			updateClientForm,
			webadnColor,
			setProjectPopUpVisible,
			setUpdateClientPopUpVisible,
			setDeleteClientPopUpVisible,
			setProjectColor,
			createNewProject,
			updateClient,
			deleteClient,
			gotToProject,
		};
	},
};
</script>

<style lang="scss" scoped>
.project-header {
	display: flex;
	gap: 16px;
	margin-bottom: 32px;
}

.page
{
	display: flex;
	flex-direction: column;
}

.hero
{
	display: flex;
	flex-direction: column;
	height: 192px;
	background-color: $BG_SECONDARY;
	border-radius: 8px;
	overflow: hidden;
	padding: 8px;
	gap: 8px;
	box-shadow: $SHD_DEFAULT;
	margin: 16px 0;

	.banner
	{
		overflow: hidden;
		border-radius: 8px;
		height: 80%;
		position: relative;

		&::after {
		  position: absolute;
		  content: "";
		  width: 100%;
		  height: 100%;
		  box-shadow: $SHD_INSET;
		  border-radius: 6px;
		  mix-blend-mode: multiply;
		  top: 0;
		  left: 0;
		}
		img
		{
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.text
	{
		z-index: 1;
		display: flex;
		align-items: flex-end;
		gap: 16px;
		margin-top: -58px;
		padding-left: 8px;

		.image
		{
			--size: 96px;
			height: var(--size);
			width: var(--size);
			box-sizing: border-box;
			background: $FG_MAIN;
			border-radius: 8px;
			box-shadow: $SHD_DEFAULT;
			overflow: hidden;
			border: 3px solid rgba(255, 255, 255, 0.2);
			img
			{
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}
}

.project-list {
	display: grid;
	grid-template-columns: 100%;
	grid-gap: 20px;
	padding: 0;
	margin: 0;
	margin-bottom: 64px;
	list-style: none;
}

.main
{
	background: $BG_TERTIARY;
	padding: 16px;
	border-radius: 8px;
	flex-grow: 1;
	overflow-y: scroll;
}

.actions {
	h2 {
		margin-bottom: 8px;
	}

	.subtitle {
		display: block;
		margin-bottom: 16px;
		font-size: 12px;
	}
}

@media screen and (min-width: 900px) {
	.project-list {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (min-width: 1200px) {
	.project-list {
		grid-template-columns: repeat(3, 1fr);
	}
}
</style>
