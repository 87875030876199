import { createRouter, createWebHistory } from 'vue-router';

import Login from '../views/Login.vue'
import BackOffice from '../views/BackOffice.vue'
import Settings from '../views/Pages/Settings.vue'
import Dashboard from '../views/Pages/Dashboard.vue'
import Page404 from '../views/Page404.vue'
import Services from '../views/Pages/Services/Services.vue'
import Service from '../views/Pages/Services/Service.vue'
import Clients from '../views/Pages/Portfolio/Clients.vue'
import Projects from '../views/Pages/Portfolio/Projects.vue'
import Client from '../views/Pages/Portfolio/Client.vue';
import Servers from '../views/Pages/Portfolio/Servers.vue';

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '',
		name: 'Back-Office',
		component: BackOffice,
		meta: {
			needs_auth: true
		},
		children: [
			{
				path: '',
				name: 'Dashboard',
				component: Dashboard
			},
			{
				path: 'settings',
				name: 'Paramètres',
				component: Settings
			},
			{
				path: 'services',
				name: 'Librairie de services',
				component: Services
			},
			{
				path: 'clients',
				name: 'Clients',
				component: Clients,
				meta: {
					parents: [
						{
							name: "Portfolio"
						}
					]
				}
			},
			{
				path: 'clients/:id',
				name: 'Client',
				component: Client,
				meta: {
					parents: [
						{
							name: 'Clients',
						},
					],
				},
			},
			{
				path: 'servers',
				name: 'Serveurs',
				component: Servers,
				meta: {
					parents: [
						{
							name: "Portfolio"
						}
					]
				}
			},
			{
				path: 'projects',
				name: 'Projects',
				component: Projects,
				meta: {
					parents: [
						{
							name: "Portfolio"
						}
					]
				}
			},
			{
				path: 'service/:id',
				name: 'Service',
				component: Service,
				meta: {
					parents: [
						{
							name: "Librairie de services"
						}
					]
				}
			}
		]
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: Page404
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
router.beforeEach((to, from, next) => {
	// Connexion a l'admin
	if (to.matched.some(record => record.meta.needs_auth)) {
		if (router.cookies.get('wa.bo.log.token') !== router.sha256(router.cookies.get('wa.bo.log.time') + "fc14e9d0423bb2b").toString()) {
			next({
				path: '/login',
			})
		}
		else {
			var time_of_connexion = Date.now().toString();
			router.cookies.set(
				"wa.bo.log.time",
				time_of_connexion,
				{
					expires: new Date(Date.now() + 1500000)
				}
			)
			router.cookies.set(
				"wa.bo.log.token",
				router.sha256(time_of_connexion + "fc14e9d0423bb2b").toString(),
				{
					expires: new Date(Date.now() + 1500000)
				}
			)
			next();
		}
	}
	else {
		next();
	}
})

const DEFAULT_TITLE = ' - BackOffice WebAdn';
router.afterEach((to, from) => {
	document.title = to.name + DEFAULT_TITLE;
});
export default router;
