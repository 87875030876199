<template lang="html">
  	<div class="breadcrumbs">
		<span class="breadcrumbs-element">
			<span class="link" @click="$router.push({name: 'Dashboard'})">
				Home
			</span>
			 /
		</span>
		<span v-for="(route, index) of $route.meta.parents">
			<span class="breadcrumbs-element" v-if="route.name != 'Back-Office' && route.name != $route.name">
				<span class="link" @click="$router.push(route)" >{{route.name}}</span>
				/
			</span>
		</span>
  	</div>
</template>

<script>
export default {
    name: "Breadcrumbs"
}
</script>

<style lang="scss" scoped>
.breadcrumbs-element
{
	color: $FG_SECONDARY;
	font-size: 14px;

	.link
	{
		cursor: pointer;

		&:hover
		{
			text-decoration: underline;
		}
	}
}
</style>
