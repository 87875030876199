<template lang="html">
	<div class="page" id="page-dashboard">
		<PageHeader :title="$route.name"/>
		<!-- Cette page est vide pour l'instant, et se remplira au gré des mises a jour. -->

		<div class="ui-dashboard">
			<GraphCard
				title="Test"
				:data="data"
				index_name="id"
				data_name="data"
				:show_data_points="30"
				size="large"
				color="gd-green"
				/>
			<ShortcutCard route="Service" color="blue">
				Hello World
			</ShortcutCard>
		</div>
	</div>
</template>

<script>
import PageHeader from "@/components/ui/prefabs/PageHeader";
import { inject, onMounted, ref, reactive, computed, watch } from "vue";

export default {
	components:
	{
		PageHeader
	},
	setup (props) {

		const data = ref([]);

		const axios = inject("axios");
		onMounted(async () => {
			var results = await axios.get("https://613f3f44e9d92a0017e17579.mockapi.io/randomnumbers")

			data.value = results.data;
		})

		return {
			data
		};
	}
}
</script>

<style lang="scss" scoped>


.ui-dashboard
{
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	height: 100%;

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	grid-auto-rows: 100px;

	grid-auto-flow: dense;
	grid-gap: 16px;
}
</style>
