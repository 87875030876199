<template lang="html">
	<div class="ui-toast" :class="toastClasses">
		<div class="toast-icon">
			<fa-icon :icon="data.icon"/>
		</div>
		<div class="toast-content">
			<h1>{{data.title}}</h1>
			<p v-html="data.content"></p>
		</div>
		<div class="timer">

		</div>
	</div>
</template>

<script>
export default {
	name: "Toast",
	data () {
		return {
			toastClasses: {}
		};
	},
	props: {
		data: {
			type: Object
		},
	},
	mounted () {
		this.toastClasses[`color-${this.data.color}`] = true;
	}
}
</script>

<style lang="scss" scoped>
.ui-toast
{
	background: $BG_TERTIARY;
	color: $BG_MAIN;
	position: relative;
	display: flex;
	align-items:center;
	pointer-events: all;
	border-radius: 8px;

	color: $FG_SECONDARY;
	overflow: hidden;

	box-shadow: $SHD_DEFAULT;

	user-select: none;
	cursor: pointer;

	transition: all 0.2s ease;

	& + &
	{
		margin-bottom: 16px;
	}

	&.color-
	{
		&gd-red
		{
			.toast-icon
			{
				background: $GD_RED;
			}
		}

		&gd-yellow
		{
			.toast-icon
			{
				background: $GD_YELLOW;
			}
		}

		&gd-blue
		{
			.toast-icon
			{
				background: $GD_BLUE;
			}
		}

		&gd-green
		{
			.toast-icon
			{
				background: $GD_GREEN;
			}
		}
	}

	.toast-icon
	{
		font-size: 32px;
		height: 100%;
		padding: 12px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		color: white;

		svg
		{
			max-width: 32px;
		}
	}

	.toast-content
	{
		padding: 16px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		h1
		{
			font-size: 20px;
			font-weight: 500;
			color: $FG_MAIN;

		    * + &
			{
				padding-bottom: 8px;
			}
		}
	}

	.timer {
		position: absolute;
		width: 100%;
		height: 3px;
		bottom: 0px;
		background-color: $FG_MAIN;
		animation: timer 10s linear forwards;
		transform-origin: left center;
	}
}

@keyframes timer {
  to {
    /* More performant than animating `width` */
    transform: scaleX(0);
  }
}
</style>
