<template lang="html">
	<Card v-bind="$props" class="ui-graphcard" ref="card" >
		<div class="content">
			<p class="card-title">{{title}}</p>
			<p class="data-value">{{data.at(-1) ? data.at(-1)[data_name]:""}}</p>

			<div class="ui-refresh" @click="generateGraph">
				<fa-icon icon="sync-alt"/>
			</div>
		</div>
		<div :id="`graph-${instance.uid}`" class="graph" :style="svgStyle"/>
	</Card>
</template>

<script>
import { inject, onMounted, getCurrentInstance, ref, reactive, computed, watch } from "vue";

export default {
	name:"GraphCard",
	inject: [
		'axios',
		'd3'
	],
	props: {
		index_name: {
			type: String,
			default: "index"
		},
		data_name: {
			type: String,
			default: "data"
		},
		data: {
			type: Array,
			default: []
		},
		title: {
			type: String
		},
		color: {
			type: String
		},
		size: {
			type: String
		},
		type: {
			type: String,
			default: "complex"
		},
		show_data_points: {
			type: Number,
			default: 0
		}
	},
	setup(props) {
		const axios = inject("axios")
		const d3 = inject("d3")

		// var card = [];
		const card = ref(null);
		const svgLength = ref(0);
		const instance = getCurrentInstance();
		console.log(instance)

		// const data = [
		// 	{"data":254,"index":83},{"data":301,"index":84},{"data":216,"index":85},{"data":894,"index":86},{"data":221,"index":87},{"data":1749,"index":88},{"data":1066,"index":89},{"data":1257,"index":90},{"data":974,"index":91},{"data":532,"index":92},{"data":506,"index":93},{"data":804,"index":94},{"data":1059,"index":95},{"data":207,"index":96},{"data":1214,"index":97},{"data":997,"index":98},{"data":1793,"index":99},{"data":432,"index":100}
		// ];

		const data = ref([]);

		const generateGraph = function() {

			if (!data.value.length) {
				console.error("Data is empty: Graph genration aborted")
				return ;
			}

			d3.selectAll("#graph-" + instance.uid + " svg").remove()

			const svg = d3.select("#graph-" + instance.uid)
				.append("svg")
				.attr("height", "100%")
				.attr("width", "100%")

			let defs = svg.append("defs")

			let gradient = defs.append("linearGradient")
			   				.attr("id", "opacity_gradient")

			gradient.append("stop")
				.attr("offset", "0%")
				.attr("stop-opacity", "0.1")
				.attr("stop-color", "white")

			gradient.append("stop")
				.attr("offset", "100%")
				.attr("stop-opacity", "1")
				.attr("stop-color", "white");


			var clientHeight = card.value.$el.clientHeight;
			var clientWidth = card.value.$el.clientWidth;

			// TODO: Rerécupérer la taille de la div pour tout remettre dedans comme il faut
			// var clientHeight = 332;
			//  var clientWidth = 579;

			var x = d3.scaleLinear()
					.domain([
						Number(data.value[0][props.index_name]),
						Number(data.value[0][props.index_name]) + Number(props.show_data_points)
					])
					.range([0, 0.75*clientWidth]);

			var y = d3.scaleLinear()
					.domain([
						d3.max(data.value, d => d.data),
						d3.min(data.value, d => d.data)
					])
					.range([75, clientHeight-50]);

			var line = d3.line()
						 .x((d)=>{
							 return x(d[props.index_name])
						 })
						 .y((d)=>{return y(d[props.data_name])})
						 .curve(d3.curveCardinal);

			var path = svg.append("path")
				.datum(data.value)
				.attr("d", line)
				.attr("pathLength", 1)
				.attr("fill", "none")
				.attr("stroke", "url(#opacity_gradient)")
				.attr("stroke-width", 5)
				.attr("stroke-linecap", "round")


		};

		onMounted(async () => {
			// data.value = ref(props.data.splice(-1*props.show_data_points));
			// generateGraph();
		})

		watch(() => props.data, (newValue, oldValue) => {
			data.value = newValue.splice(-1*props.show_data_points);
		  	generateGraph();
		})

		const svgStyle = computed(() => {
			return {
				"--length": svgLength.value,
				"--offset": svgLength.value
			};
		});

		return {
			card,
			data,
			generateGraph,
			svgStyle,
			svgLength,
			instance
		};
	}
}
</script>

<style lang="scss" scoped>

.ui-graphcard
{
	position: relative;

	.content
	{
		padding: 16px;
		z-index: 100;

		.card-title
		{
			font-weight: 700;
			font-size: 24px;
			user-select: none;
		}

		.data-value
		{
			user-select: none;
			position: absolute;
			margin: 0;
			padding: 0;
			right: 16px;
			top: 3px;
			font-size: 72px;
			opacity: .5;
			color: white;
			mix-blend-mode: screen;
			font-weight: 700;
		}

		.ui-refresh
		{
			position: absolute;
			bottom: 8px;
			right: 16px;
			color: transparentize($FG_MAIN, 0.25);
			cursor: pointer;
			padding: 8px;
			border-radius: 4px;
			transition: all 0.2s ease;
			mix-blend-mode: screen;

			&:hover
			{
				color: $FG_MAIN;
				background-color: transparentize($FG_MAIN, 0.75);
			}
		}
	}

	.graph
	{
		position: absolute;
		top:0;
		left: 0;
		z-index: 0;

		pointer-events: none;

		height: 100%;
		&::v-deep svg
		{
			path {

				stroke-dasharray: 1;
				stroke-dashoffset: 1;
				animation: dash 3s cubic-bezier(0,0,0,.99) forwards;
			}
		}
	}
}


@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}
</style>
