<template lang="html">
    <div class="ui-button" :class="buttonClasses" ref="ui-button" tabindex="0" role="button" @keyup.enter="$emit('click', $event)">
        <fa-icon v-if="icon" class="icon" :icon="icon"/>
        <span v-if="$slots.default" class="text">
            <slot></slot>
        </span>
    </div>
</template>

<script>
export default {
    name: "Button",
    data () {
        return {
            buttonClasses : {}
        };
    },
    props: {
        type: {
            type: String,
            default: "primary"
        },
        color: {
            type: String,
            default: "white"
        },
        icon: {
            type: [String, Array],
        },
        size: {
            type: String,
            default: "default"
        },
        title: {
            type: String,
            default: ""
        }
    },
    mounted () {
        this.buttonClasses[`type-${this.type}`] = true;
        this.buttonClasses[`color-${this.color}`] = true;
        this.buttonClasses[`size-${this.size}`] = true;
    },
}
</script>

<style lang="scss" scoped>

.ui-button
{
    cursor: pointer;
    font-weight: 400;

    $BTN_MAIN: $FG_MAIN;
    $BTN_ALT: $BG_MAIN;

    user-select: none;

    background-color: $BTN_MAIN;
    color: $BTN_ALT;

    display: inline-flex;
    border-radius: 8px;
    align-items: center;

    transition: all 0.1s ease;

    .icon + .text
    {
        margin-left: 8px
    }



    & + .ui-button
    {
        margin-left: 16px;
    }

    &:hover
    {
        background-color: white;
    }

    &.type-
    {
        &secondary
        {
            background-color: transparent;
            color: white;
            border: 2px solid $BTN_MAIN;
            padding: 6px 14px;

            &:hover
            {
                background-color: white;
                color: $BG_MAIN;
            }
        }

        &tertiary
        {
            background-color: transparent;
            color: $BTN_MAIN;
            text-decoration: underline;
            padding: 8px 0px;

            &:hover
            {
                color: white;
            }
        }
    }

    &.size-
    {
        &small
        {
            padding: 6px 10px;
            font-size: 0.90rem;
        }
        &default
        {
            padding: 8px 16px;
            font-size: 1rem;
        }
        &huge
        {
            padding: 12px 24px;
            font-size: 1.5rem;
        }
    }

    &.color-
    {

        &gd-blue
        {
            background: $GD_BLUE;
            color: white;
        }
        &gd-red
        {
            background: $GD_RED;
            color: white;
        }
        &gd-green
        {
            background: $GD_GREEN;
            color: white;
        }
        &gd-yellow
        {
            background: $GD_YELLOW;
            color: white;
        }

        &blue
        {
            background-color: $AC_BLUE;
            color: $FG_MAIN;


            &:hover
            {
                background-color: lighten($AC_BLUE, 5%);
            }
        }
        &wa-blue
        {
            background-color: $WA_BLUE;
            color: $FG_MAIN;

            &:hover
            {
                background-color: lighten($WA_BLUE, 5%);
            }
        }
        &red
        {
            background-color: $AC_RED;
            color: $FG_MAIN;

            &:hover
            {
                background-color: lighten($AC_RED, 5%);
            }
        }
        &green
        {
            background-color: $AC_GREEN;
            color: white;

            &:hover
            {
                background-color: lighten($AC_GREEN, 5%);
            }
        }
        &yellow
        {
            background-color: $AC_YELLOW;
            color: $BG_MAIN;

            &:hover
            {
                background-color: darken($AC_YELLOW, 10%);
            }
        }
        &grey, &gray, &dark
        {
            background-color: $BG_TERTIARY;
            color: $FG_SECONDARY;

            &:hover
            {
                background-color: lighten($BG_TERTIARY, 2%);
            }
        }
    }

}



</style>
<style lang="scss">

.ui-button.color-
{
    &blue
    {
        .duotone
        {

            .fa-primary
            {
                opacity: 1 !important;
                color: $FG_MAIN;
            }

            .fa-secondary
            {
                opacity: 1 !important;
                color: $AC_BLUE;
            }
        }
    }
    &wa-blue
    {
        .duotone
        {

            .fa-primary
            {
                opacity: 1 !important;
                color: $FG_MAIN;
            }

            .fa-secondary
            {
                opacity: 1 !important;
                color: $WA_BLUE;
            }
        }
    }
    &red
    {
        .duotone
        {

            .fa-primary
            {
                opacity: 1 !important;
                color: $FG_MAIN;
            }

            .fa-secondary
            {
                opacity: 1 !important;
                color: $AC_RED;
            }
        }
    }
    &green
    {
        .duotone
        {

            .fa-primary
            {
                opacity: 1 !important;
                color: $FG_MAIN;
            }

            .fa-secondary
            {
                opacity: 1 !important;
                color: $AC_GREEN;
            }
        }
    }
    &yellow
    {
        .duotone
        {

            .fa-primary
            {
                opacity: 1 !important;
                color: $BG_TERTIARY;
            }

            .fa-secondary
            {
                opacity: 1 !important;
                color: $AC_YELLOW;
            }
        }
    }
    &grey, &gray
    {
        .duotone
        {

            .fa-primary
            {
                opacity: 1 !important;
                color: $FG_SECONDARY;
            }

            .fa-secondary
            {
                opacity: 1 !important;
                color: $BG_TERTIARY;
            }
        }
    }
}
</style>
