<template>
    <div id="page-404">
        <div class="login-form">
            <div class="side-image" @click="loadNewGif">
                <img :src="gif">
            </div>
            <div class="form-content">
                <div class="header">
                    <h1>404 - Oups !</h1>
                    <h2 class="subtitle">Hola voyageur, vous semblez être perdu.</h2>
                </div>

                <div class="form-fields">
                    <p>La page que vous cherchez n'a pas l'air d'exister. Voici quelques options pour vous y retrouver.</p>
                </div>

                <div class="actions">
                    <Button color="wa-blue" @click="$router.push({name: 'Dashboard'})">
                        Acceuil
                    </Button>
                    <Button color="wa-blue" @click="$router.push({name: 'Login'})">
                        Page de connexion
                    </Button>
                </div>

            </div>

    	</div>
    </div>
</template>

<script>
// @ is an alias to /src

import sha256 from 'crypto-js/sha256';

export default {
    name: '404',
    data () {
        return {
            gif: ""
        }
    },
    mounted () {
        this.loadNewGif ()
    },
    methods: {
        loadNewGif () {
            var gifs = [
                //Coraline
                "https://media1.giphy.com/media/KqnqIiDK6RDqrd6JO6/giphy.gif",
                //Joey
                "https://i.kym-cdn.com/photos/images/newsfeed/001/697/667/95f.gif",
                "https://media1.giphy.com/media/CilAbpLI2APlV4UdYX/giphy.gif?cid=ecf05e4785lo4hqvxn6e3aqefdlbarrhfffqgvzf9zk0fhov&rid=giphy.gif&ct=g",
                "https://media0.giphy.com/media/fo9EFSInji9gY/giphy.webp?cid=ecf05e47w3btp20fn34aanq802gx2xs9nrle8vss0br202h1&rid=giphy.webp&ct=g"
            ]

            var newGif;
            do {
                newGif = gifs[Math.floor(Math.random()*gifs.length)];
            } while (newGif == this.gif);
            this.gif = newGif;
        }
    }
}
</script>

<style lang="scss" scoped="">
#page-404
{
    background-color: #161616;
    background-image: $PTN_TOPO;

    display: flex;
    justify-content: center;
    align-items: center;

    .login-form
    {
        background-color: $BG_SECONDARY;
        width: 50%;
        height: 50%;

		max-width: 800px;
		max-height: 384px;
        overflow: hidden;

        box-shadow: $SHD_DEFAULT;

        border-radius: 16px;
		min-width: 342px;

        display: flex;

        .form-content
        {
            padding: 32px;
            display: flex;
            flex-direction: column;


            .header
            {
                h1
                {
                    margin: 0;
                    font-size: 32px;
                }

                h2.subtitle
                {
                    font-size: 18px;
                    font-weight: 100;
                    margin: 0;
                }
            }

            .form-fields
            {
                flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
            }

            .actions
            {
                display: flex;
                flex-direction: row-reverse;

                & > * + *
                {
                    margin-right: 16px;
                }
            }
        }

        .side-image
        {
            position: relative;

            flex-grow: 1;
            width: 30%;
            max-width: 256px;
            min-width: 256px;
            height: 100%;
            background-color: $WA_YELLOW;

            display: flex;
            justify-content: center;
            align-items: center;

            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

        }
    }
}

@media (min-width: 1px) and (max-width: 1200px) {

	.side-image
	{
		display: none !important;
	}

}
</style>
