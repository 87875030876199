<template lang="html">
	<div class="page" id="page-service-list">
		<PageHeader :title="$route.name"/>

		<div class="searchbar">
			<SearchInput type="text" placeholder="Rechercher un nom, une description ..." :isLoading="isSearchbarLoading" icon="search" v-model:value="filterText" @input='searchServiceTypes'/>
			<Button class="add-service" color="blue" icon="plus" @click="setServicePopUpVisible(true)">  </Button>
		</div>
		<div class="service-list">
			<div @click="$router.push({name: 'Service', params: {id: serviceType.name}})" class="service" v-for="serviceType in serviceTypes" :key="serviceType">
				<div class="header">
					<h1 class="title">{{serviceType.name}}</h1>
				</div>
				<div class="content">
					<p>
						{{serviceType.description}}
					</p>
				</div>
				<div class="actions">
					<a v-if="serviceType.dockerRepository" :href="serviceType.dockerRepository" class="link" >
						<fa-icon :icon="['fab','docker']"/>
					</a>
					<a v-if="serviceType.gitlabRepository" :href="serviceType.gitlabRepository" class="link">
						<fa-icon :icon="['fab','gitlab']"/>
					</a>
					<div class="link">
						<fa-icon :icon="['far','ellipsis-v']"/>
					</div>
				</div>
			</div>
		</div>

		<PopUpWrapper :isVisible="isAnyPopUpShown">
			<PopUp :isVisible="isServiceTypeFormShown">
				<template v-slot:header>
					<h1>Nouveau Service</h1>
				</template>

				<template v-slot:content>
					<FormInput v-model:value="serviceTypeForm.name" label="Nom du service" />
					<FormInput v-model:value="serviceTypeForm.description" label="description" type="textarea" placeholder="Lorem ipsum dolor sit amet ..."/>
					<FormInput v-model:value="serviceTypeForm.gitlabRepository" length="long" label="Dépot Gitlab" />
					<FormInput v-model:value="serviceTypeForm.dockerRepository" length="long" label="Dépot DockerHub" />
				</template>

				<template v-slot:actions>
					<Button color="blue" @click='createNewServiceType'> Creer </Button>
					<Button type="tertiary" @click="setServicePopUpVisible(false)"> Annuler </Button>
				</template>
			</PopUp>
		</PopUpWrapper>



	</div>
</template>

<script>
import PageHeader from "@/components/ui/prefabs/PageHeader";
import { inject, onMounted, ref, reactive, computed } from "vue";

export default {
	name: "Services",
	inject: [
		'axios'
	],
	setup (props, { emit }) {
		var axios = inject("axios");
		const SERVICE_LIBRARY_URL = inject("SERVICE_LIBRARY_URL");

		const serviceTypes = ref([]);
		const filterText = ref("");
		const currentTimeout = ref("");
		const isSearchbarLoading = ref(false);
		const isServiceTypeFormShown = ref(false);
		const serviceTypeForm = reactive({
			name: "",
			description: "",
			gitlabRepository: "",
			dockerRepository: "",
		})
		const searchServiceTypes = async (event) => {

			if (currentTimeout.value) {
				clearTimeout(currentTimeout.value);
			}
			isSearchbarLoading.value = true;

			currentTimeout.value = setTimeout(async function () {
				var db_serviceTypes = await axios.get(`${SERVICE_LIBRARY_URL}/serviceTypes`, {
					params: {
						filterText: filterText.value
					}
				})
				serviceTypes.value = db_serviceTypes.data.serviceTypes;
				isSearchbarLoading.value = false;
			}, 300);



		}

		const setServicePopUpVisible = async function (isPopUpVisible) {
			isServiceTypeFormShown.value = isPopUpVisible;
		}

		const isAnyPopUpShown = computed(() => {
			return isServiceTypeFormShown.value; // || isAnyOtherPopUpShown
		})

		const createNewServiceType = async () => {
			axios.post(
				`${SERVICE_LIBRARY_URL}/serviceTypes`,
				{
					serviceType: serviceTypeForm
				}
			)
			.then(() => {
				filterText.value = "";
				searchServiceTypes();
				setServicePopUpVisible();
				emit(
					"toast",
					{
						title:"Succès",
						content: "Service créé",
						color: "gd-green",
						icon: "check"
					}
				)
			})
			.catch((error) => {
				emit(
					"toast",
					{
						title:"Erreur",
						content: error.message,
						color: "gd-red",
						icon: "cog"
					}
				)
			})
		}

		onMounted( async () => {
			var db_serviceTypes = await axios.get(`${SERVICE_LIBRARY_URL}/serviceTypes`)
			serviceTypes.value = db_serviceTypes.data.serviceTypes;
		});

		return {
			serviceTypes,
			filterText,
			searchServiceTypes,
			isSearchbarLoading,
			isServiceTypeFormShown,
			setServicePopUpVisible,
			isAnyPopUpShown,
			serviceTypeForm,
			createNewServiceType
		}
	}
}
</script>

<style lang="scss" scoped>

#page-service-list
{

	width: 100%;
	max-width: 100%;

	.searchbar
	{
		margin-bottom: 32px;
		display: flex;

		.add-service
		{
			flex-grow: 1
		}

		*
		{
			box-shadow: $SHD_DEFAULT;
		}

		& > * + *
		{
			margin-left: 16px;
		}

	}

	.service-list
	{
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) ;
		grid-auto-rows: 150px;
		gap: 32px;
		grid-auto-flow: row dense;

		width: 100%;

		.service
		{
			background: $BG_MAIN;
			border-radius: 8px;
			box-shadow: $SHD_DEFAULT;

			cursor: pointer;

			padding: 16px;

			display: flex;
			flex-direction: column;

			h1.title
			{
				font-size: 24px;
				font-weight: 500;
				text-transform: capitalize;
				margin-bottom: 16px;
			}

			.content
			{
				flex-grow: 1;
				overflow: hidden;
				position: relative;
				font-size: 0.85rem;
				&::before

				{
					content: "";
					position: absolute;
					height: 24px;
					width: 100%;
					bottom: 0;
					background: linear-gradient(180deg, rgba(22,22,22,0) 0%, $BG_MAIN 100%);;
				}
			}

			.actions
			{
				display: flex;
				justify-content: flex-end;

				.link
				{
					color: $FG_TERTIARY;
					font-size: 20px;
					width: 32px;

					display: flex;
					align-items: center;
					justify-content: center;

					box-sizing: border-box;

					cursor: pointer;
					&:hover
					{
						color: $FG_MAIN;
					}
				}


			}
		}
	}

	position: relative;

}

</style>
