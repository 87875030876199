<template>
	<div class="page" id="server-list">
		<PageHeader title="Servers" />
		<transition name="post-loading" mode="out-in">
			<div class="main" v-if="!isLoading">
				<div class="actions">
					<Button color="blue" @click="loadServers">
						<fa-icon icon="sync"/>
						Refresh
					</Button>
					<Button color="dark" @click="pingServers">
						<fa-icon icon="server"/>
						Ping
					</Button>
				</div>
				<div class="hero-div" v-if="buggedServers.length === 0">
					<fa-icon size="4x" :icon="['far','badge-check']"/>
					<div class="status-text">
						<h1>Tout Va Bien !</h1>
						<p class="subtext">Aucun problème de serveurs détectés lors du dernier check</p>
					</div>

				</div>
				<div class="hero-div error" v-else>
					<fa-icon size="4x" :icon="['far','bug']"/>
					<div class="status-text">
						<h1 v-if="buggedServers.length > 1">{{buggedServers.length}} serveurs sont downs !</h1>
						<h1 v-else >Un serveur est down !</h1>
						<p class="subtext">Des problèmes ont été détectés sur les serveurs</p>
					</div>

				</div>

				<div class="server-list">
					<div class="headers">
						<span class="server__title">Nom</span>
						<span class="server__url" >URL</span>
						<span class="server__status"> Status </span>
					</div>
					<div class="server__wrapper">
						<div class="server" v-for="server in servers" :key="server">
							<span class="server__title">{{ server.name }}</span>
							<a class="server__url" :href="server.url">{{ server.url }}</a>
							<span class="server__status" :class="{'error':!server.isUp}" > {{server.status}} </span>
						</div>
					</div>
				</div>

			</div>
			<div class="loader" v-else>
				<fa-icon :icon="['fal','circle-notch']" spin size="8x"/>
			</div>
		</transition>
	</div>

</template>

<script>
import { inject, ref } from "vue";

export default {
	components: {},
	name: "servers",
	props: {},
	setup(props) {
		const axios = inject("axios");
		const GATEWAY_URL = inject("GATEWAY_URL")
		const isLoading = ref(true);
		const servers = ref([]);
		const buggedServers = ref([]);

		const loadServers = async function () {
			isLoading.value = true;
			try {
				const results = await axios.get(
					`${GATEWAY_URL}/v1/servers`
				);
				servers.value = results.data.sort((a, b) =>{
					return a.isUp > b.isUp;
				});
				buggedServers.value = results.data.filter(s => s.isUp === false);
				isLoading.value = false;
			}
			catch (e) {
				servers.value = [];
				ctx.emit("toast", {
					content: "Une erreur est survenue lors de la récupération des données",
					color: "gd-red",
					icon: "warning"
				});
				console.error(e);
				isLoading.value = false;
			}
		};

		const pingServers = async function() {
			const results = await axios.get(
				`${GATEWAY_URL}/v1/pingServers`
			);
			loadServers();
		}

		loadServers();

		return {
			//Variables
			servers,
			isLoading,
			buggedServers,
			//Functions
			loadServers,
			pingServers
		};
	}
}
</script>

<style scoped lang="scss">
#server-list
{
	max-height: 100vh;
	display: flex;
	flex-direction: column;
}


.main
{
	display: flex;
	flex-direction: column;
	height: 100%;
	flex-grow: 1;
	& > div + div
	{
		margin-top: 32px;
	}
}

.loader
{
	color: $FG_TERTIARY;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50%;
}

.hero-div
{
	background: $BG_SECONDARY;
	height: 128px;
	border-radius: 8px;
	box-shadow: $SHD_DEFAULT;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	flex-grow: 0;
	flex-shrink: 0;

	&.error
	{
		background: $AC-RED;

		.status-text
		{
			.subtext
			{
				color: white;
			}
		}
	}

	.status-text
	{
		.subtext
		{
			color: $FG_SECONDARY;
			user-select: none;
		}
	}
}

.server-list {
	display: flex;
	flex-direction: column;
	padding: 16px;
	background-color: $BG_TERTIARY;
	border-radius: 8px;
	gap: 4px;

	flex-grow: 1;
	flex-shrink: 1;

	.headers
	{
		font-weight: 700;
		color: $FG_SECONDARY;
		text-transform: uppercase;
		display: grid;
		grid-template-columns: minmax(250px, 25%) minmax(100px, 50%) auto;
		user-select: none;
	}

	.server__wrapper
	{
		overflow-y: scroll;
	}

	.server
	{
		display: grid;
		grid-template-columns: minmax(250px, 25%) minmax(100px, 50%) auto;

		.server__url
		{
			font-family: monospace;
		}

		.server__status
		{
			color: $FG_TERTIARY;
			text-transform: uppercase;

			&.error
			{
				color: $AC_RED;
			}
		}
	}
}

@media screen and (min-width: 900px) {
	.server-list {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (min-width: 1200px) {
	.server-list {
		grid-template-columns: repeat(3, 1fr);
	}
}
.post-loading-enter-active {
  animation: post-loading .3s;
}
.post-loading-leave-active {
  animation: post-loading .15s reverse;
}
@keyframes post-loading {
  0% {
    opacity: 0;
	transform: translateY(5px);
  }
  100% {
    opacity: 1;
	transform: translateY(0px);
  }
}
</style>
