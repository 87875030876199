<template lang="html">
	<div class="popup" v-if="isVisible">
		<div class="header">
			<slot name="header"/>
		</div>
		<div class="content">
			<slot name="content"/>
		</div>
		<div class="actions">
			<slot name="actions"/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isVisible: {
			type: Boolean
		}
	}
}
</script>

<style lang="scss" scoped>

.popup
{
	max-width: 70%;
	min-height: 128px;
	background-color: $BG_MAIN;
	box-shadow: $SHD_DEFAULT;
	box-sizing: border-box;
	border-radius: 8px;

	padding: 32px;
	.header
	{
		margin-bottom: 32px;
	}

	.actions
	{
		display: flex;
		flex-direction: row-reverse;
		margin-top: 32px;

		* + *
		{
			margin: 0;
			margin-right: 16px
		}
	}

}
</style>
