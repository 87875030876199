<template lang="html">
	<div class="ui-kebab-menu" ref="button" :class="kebabMenuClasses">
		<div class="ui-kebab-button" @click="toggleMenu">
			<Button color="grey" icon="ellipsis-v"/>
		</div>
		<transition name="show-menu">
			<div v-if="isMenuOpen" class="ui-kebab-content">
				<slot name="default" @close="closeMenu" :closeParentMenu="closeMenu">

				</slot>
			</div>
		</transition>
	</div>
</template>

<script>
import { ref, onMounted, provide, watch, getCurrentInstance } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default {
	name: "KebabMenu",
	props: {
		"x-direction": {
			type: String,
			default: "right"
		},
		"y-direction": {
			type: String,
			default: "down"
		}
	},
	setup(props){

		// Data initialisation
		const isMenuOpen = ref(false)
		const kebabMenuClasses = ref({});
		const button = ref(null);

		// Methods
		const closeMenu = function() {
			isMenuOpen.value = false;
		}

		const openMenu = function() {
			isMenuOpen.value = true;
		}

		const toggleMenu = function() {
			isMenuOpen.value = !(isMenuOpen.value);
		}

		provide('closeMenu', closeMenu);

		// Mounted
		onMounted(() => {
			let classObject = {};
			classObject[`x-direction-${props.xDirection}`] = true;
			classObject[`y-direction-${props.yDirection}`] = true;

			kebabMenuClasses.value = classObject;
		})
		onClickOutside(button, (event) => {
			closeMenu();
		})

		return {
			kebabMenuClasses,
			isMenuOpen,
			closeMenu,
			openMenu,
			toggleMenu,
			button
		};
	}
}
</script>

<style lang="scss" scoped>

.ui-kebab-menu
{
	position: relative;
	display: inline-block;

	.ui-kebab-content
	{
		border-radius: 8px;
		position: absolute;
		min-width: 160px;
		background-color: $BG_TERTIARY;
		padding: 16px;
		box-shadow: $SHD_DEFAULT;
		z-index: 100;
		border: 1px solid $BG_MAIN;
	}

	&.x-direction-
	{
		&left
		{
			.ui-kebab-content
			{
				right: 0px;
			}
		}
		&right
		{
			.ui-kebab-content
			{
				left: 0px;
			}
		}
	}
	&.y-direction-
	{
		&up
		{
			.ui-kebab-content
			{
				bottom: 40px;
			}
		}
		&down
		{
			.ui-kebab-content
			{
				top: 40px;
			}
		}
	}
}

.show-menu-enter-active {
  animation: show-menu .1s;
}
.show-menu-leave-active {
  animation: show-menu .1s reverse;
}

@keyframes show-menu {
  0% {
    opacity: 0;
	margin-top: -16px;
  }
  100% {
    opacity: 1;
	margin-top: 0;
  }
}
</style>
