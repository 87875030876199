<template lang="html">
	<div class="page" id="page-service">
		<div class="header">
			<PageHeader :title="service.name"/>
			<div class="description">
				{{service.description}}
			</div>
		</div>

		<div class="content">


			<div class="header">
				<SearchInput v-model:value="filterString" placeholder="Identifiant de la route ..."> </SearchInput>
				<a v-if="service.gitlabRepository" :href="service.gitlabRepository">
					<Button :icon="['fab', 'gitlab']" color="grey">
					 </Button>
				</a>
				<a v-if="service.dockerRepository" :href="service.dockerRepository">
					<Button :icon="['fab', 'docker']" color="grey">  </Button>
				</a>
				<Button v-if="currentVersion" color="blue" @click="addRoute" >
					<fa-layer>
						<fa-icon icon="road"/>
						<fa-icon
						class="duotone"
						:icon="['fad', 'plus-circle']"
						transform="shrink-3 left-5 down-5"
						/>
					</fa-layer>
				</Button>
				<InputDropdown :length="'small'" v-model="currentVersion" contentKey="version" :contents="versionList" >
					<template v-slot:placeholder>
						<span>Version</span>
					</template>
				</InputDropdown>
				<KebabMenu x-direction="left">
					<KebabMenuTitle title="Versions"/>
					<KebabMenuItem v-if="!currentVersion.default" :icon="['far','star']" title="Par défaut" @click="setVersionAsDefault()"/>
					<KebabMenuItem :icon="['far','plus']" title="Ajouter" @click="setVersionPopUpVisible(true)"/>
					<KebabMenuItem color="red" v-if="currentVersion" :icon="['far','trash-alt']" title="Supprimer" />
					<KebabMenuSeparator v-if="currentVersion" />
					<KebabMenuTitle v-if="currentVersion" title="routes"/>
					<KebabMenuItem v-if="currentVersion" :icon="['far','empty-set']" title="Vider les routes" @click="clearRoutes"/>
					<KebabMenuItem  v-if="currentVersion" :icon="['far','sync-alt']" title="Rafraichir" @click="loadRoutes"/>
				</KebabMenu>
			</div>
			<div class="main-content">
				<div v-if="currentVersion" class="operation-table">
					<div class="row header">
						<div class="cell identifier">
							Identifiant
						</div>
						<div class="cell method">
							Méthode
						</div>
						<div class="cell route">
							Route
						</div>
						<div class="cell actions">
							Actions
						</div>
					</div>
					<div class="row" v-for="(operation, index) in filteredOperations">
						<Label v-if="operation.isEdited" class="edited-dot" color="blue"/>
						<div class="cell identifier">
							<FormInput length="fill" font="mono" @update:value="operation.isEdited=true" v-model:value="operation.identifier"/>
						</div>
						<div class="cell method">
							<InputDropdown :contents="opMethods" @update:modelValue="operation.isEdited=true" :style="'minimal-dark'" v-model="operation.method" length="fill">

							</InputDropdown>
						</div>
						<div class="cell route">
							<FormInput length="fill" font="mono" @update:value="operation.isEdited=true" v-model:value="operation.route"/>
						</div>
						<div class="cell actions">
							<KebabMenu x-direction="left">
								<KebabMenuTitle title="Route"/>
								<KebabMenuItem
								title="Dupliquer"
								:icon="['far','copy']"
								@click="duplicateRoute(index)"/>
								<KebabMenuItem
									title="Supprimer"
									color="red"
								 	:icon="['far','trash-alt']"
									@click="deleteRoute(index)"/>
								<!-- <Button size="small" @click="deleteRoute(index)" icon="trash" color="red"/>
								<Button size="small" @click="duplicateRoute(index)" icon="copy" color="blue"/> -->
							</KebabMenu>
						</div>
					</div>
				</div>
				<div v-else class="no-version">
					<h3>Aucune version n'est selectionnée</h3>
					<p>Il est nécéssaire de créer une version avant de pouvoir renseigner des routes.</p>

					<Button class="cta" color="grey" icon="plus" @click="setVersionPopUpVisible(true)"> Creer Une Version </Button>
				</div>
			</div>
		</div>

		<div class="floating-actions">
			<Button v-if="isFormSaveable" :icon="['far', 'save']" @click="saveRoutes" size="huge" color="blue">
				Enregistrer
			</Button>
		</div>

		<PopUpWrapper :isVisible="isVersionTypeFormShown">
			<PopUp :isVisible="isVersionTypeFormShown">
				<template v-slot:header>
					<h1>Nouvelle version</h1>
				</template>

				<template v-slot:content>
					<FormInput v-model:value="serviceVersionForm.version" label="Numéro de version" />
					<br>
					<Checkbox v-if="versionList.length > 0" label="Dupliquer une version" v-model:value="isRouteDuplicated"/>
					<InputDropdown v-if="isRouteDuplicated" :length="'small'" v-model="serviceVersionToDuplicate" contentKey="version" :contents="versionList" >
						<template v-slot:placeholder>
							<span>Version</span>
						</template>
					</InputDropdown>
				</template>

				<template v-slot:actions>
					<Button color="blue" @click='createNewServiceVersion'> Creer </Button>
					<Button type="tertiary" @click="setVersionPopUpVisible(false)"> Annuler </Button>
				</template>
			</PopUp>
		</PopUpWrapper>
	</div>
</template>

<script>
	import { inject, onMounted, ref, reactive, computed, watch } from "vue";
	import { useRoute } from 'vue-router'

	export default {
		name: "Service",
		inject: [
			'axios'
		],
		setup(props, { emit }) {
			const axios = inject("axios");
			const SERVICE_LIBRARY_URL = inject("SERVICE_LIBRARY_URL");
			const currentRoute = useRoute();
			// DATA
			const serviceVersionForm = reactive({
				version: "",
			})
			const isVersionTypeFormShown = ref(false)
			const opMethods = [
				"GET",
				"POST",
				"PATCH",
				"PUT",
				"DELETE",
			]

			const service = ref({})
			const versionList = ref([])
			const filterString = ref("")
			const currentVersion = ref("")
			const currentVersionOperations = ref([])


			const hasARouteBeenDeleted = ref(false);
			const isRouteDuplicated = ref(false);
			const serviceVersionToDuplicate = ref(false);


			// METHODS
			const loadService = async function () {
				let results = await axios.get(`${SERVICE_LIBRARY_URL}/serviceTypes/${currentRoute.params.id}`)
				let versionResults = await axios.get(`${SERVICE_LIBRARY_URL}/serviceTypes/${currentRoute.params.id}/versions`)


				// TODO: add version info
				for (var version of versionResults.data.versions) {
					if (version.default) {
						currentVersion.value = version;
						break;
					}
				}

				service.value = results.data.serviceType;
				service.value.versions = versionResults.data.versions;
			}
			const setVersionPopUpVisible = async function (isPopUpVisible) {
				isVersionTypeFormShown.value = isPopUpVisible;
			}
			const loadRoutes = async function () {
				let routesResults = await axios.get(`${SERVICE_LIBRARY_URL}/serviceTypes/${currentRoute.params.id}/versions/${currentVersion.value.version}/routes`)

				currentVersionOperations.value = routesResults.data.operations;
				hasARouteBeenDeleted.value = false;

			}

			const clearRoutes = async function () {
				currentVersionOperations.value = [];
				hasARouteBeenDeleted.value = true;
			}

			const saveRoutes = async function() {
				axios.put(`${SERVICE_LIBRARY_URL}/serviceTypes/${currentRoute.params.id}/versions/${currentVersion.value.version}/routes`,
				{
					operations: currentVersionOperations.value
				})
				.then(() => {
					for (var route of currentVersionOperations.value) {
						route.isEdited = false;
						hasARouteBeenDeleted.value=false;
					}
				})
			}


			const setVersionAsDefault = async function() {
				axios.patch(`${SERVICE_LIBRARY_URL}/serviceTypes/${currentRoute.params.id}/versions/${currentVersion.value.version}`,
				{
					version : {
						default: true
					}
				})
				.then(() => {
					loadService();
				})
			}

			const addRoute = function () {
				currentVersionOperations.value.push({
					identifier: "",
					method:"GET",
					route:"/",
					isEdited:"true"
				})
			}

			const deleteRoute = function (index) {
				var deletedRoute = currentVersionOperations
					.value
					.splice(index, 1)

				if (!deletedRoute.edited) {
					hasARouteBeenDeleted.value = true;
				}

			}

			const duplicateRoute = function (index) {

				var routeToDuplicate = currentVersionOperations.value[index]

				currentVersionOperations
					.value
					.push({
						identifier:  routeToDuplicate.identifier,
						method: routeToDuplicate.method,
						route: routeToDuplicate.route,
						isEdited:"true"
					})
			}

			const deleteCurrentVersion = function (index) {

				axios.delete(`${SERVICE_LIBRARY_URL}/serviceTypes/${currentRoute.params.id}/versions/${currentVersion.value.version}`)
					.then((results) => {
						emit("toast", {
							title: "Suppression Effectuée"
						})
					})
					.catch((error) => {
						emit("toast", {
							title: "Erreur",
							content: error
						})
					})

					loadService();
			}

			const createNewServiceVersion = async () => {
				axios.post(
					`${SERVICE_LIBRARY_URL}/serviceTypes/${service.value.name}/versions`,
					{
						version: serviceVersionForm,
						versionToDuplicate: serviceVersionToDuplicate.value
					}
				)
				.then(() => {
					filterString.value = "";
					loadService();
					setVersionPopUpVisible(false);
					// serviceVersionForm.version.value = ""
					emit(
						"toast",
						{
							title: "Succès",
							content: "Version créée",
							color: "gd-green",
							icon: "check"
						}
					)
				})
				.catch((error) => {
					emit(
						"toast",
						{
							title:"Erreur",
							content: error.message,
							color: "gd-red",
							icon: "cog"
						}
					)
				})
			}



			watch(currentVersion, (oldValue, newValue) => {
					loadRoutes();
			})

			watch(service, (oldValue, newValue) => {
				var list = [];

				for (var version of service.value.versions) {
					list.push(version);
				}

				versionList.value = list;
			})


			const filteredOperations = computed(() => {
				var reg = new RegExp(filterString.value, "i")

				return currentVersionOperations
					.value
					.filter((operation) => {
						return reg.test(operation.identifier) || reg.test(operation.route) || reg.test(operation.method);
					})
			})

			const isFormSaveable = computed(() => {
				if (!currentVersionOperations.value.length) {
					return false;
				}
				//Get form field status
				var areThereEditedFields = false;
				for (var route of currentVersionOperations.value) {
					if (route.isEdited) {
						areThereEditedFields = true;
						break;
					}
				}

				return areThereEditedFields || hasARouteBeenDeleted.value;
			})

			onMounted( async () => {
				await loadService();
				if (currentVersion.value) {
					await loadRoutes();
				}
			})

			return {
				service,
				addRoute,
				deleteRoute,
				saveRoutes,
				isFormSaveable,
				duplicateRoute,
				filterString,
				setVersionPopUpVisible,
				filteredOperations,
				serviceVersionForm,
				isVersionTypeFormShown,
				opMethods,
				versionList,
				currentVersion,
				currentVersionOperations,
				createNewServiceVersion,
				isRouteDuplicated,
				serviceVersionToDuplicate,
				clearRoutes,
				loadRoutes,
				deleteCurrentVersion,
				setVersionAsDefault
			};
		}
	}
</script>

<style lang="scss" scoped>

#page-service
{
	& > .header
	{

		margin-bottom: 32px;

		.description
		{
			margin-top: -32px;
		}
	}
	display: flex;
	flex-direction: column;
	max-height: 100%;

	.content
	{
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		& > .header
		{
			display: flex;
			margin-bottom: 16px;
			align-items: center;


			& > *+*
			{
				margin-left: 16px;
			}
		}

		.main-content
		{
			width: 100%;
			flex-grow: 1;

			.no-version
			{
				color: $FG_TERTIARY;
				padding: 32px 0px;
				user-select: none;
				.cta
				{
					margin-top: 32px;
				}
			}

			.operation-table
			{
				width: 100%;
				box-sizing: border-box;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				background-color: $BG_TERTIARY;
				padding: 8px;
				overflow-y: scroll;
				height: 100%;



				.row
				{
					display: grid;
					grid-template-columns: 25% 164px auto 100px;
					position: relative;

					.edited-dot
					{
						position: absolute;
						left: -4px;
						top: 45%;
					}

					&.header
					{
						margin: 0;

						.cell
						{
							font-weight: 700;
							text-transform: uppercase;
							color: $FG_SECONDARY
						}
					}

					.cell
					{
						padding: 4px 8px;
						display: flex;
						align-items: center;

						&.actions
						{
							display: flex;
							flex-direction: row-reverse;

							&>*+*
							{
								margin-right: 8px;
							}
						}
					}
				}


			}
		}
	}

	.floating-actions{
		position: fixed;

		bottom: 64px;
		right: 32px;

	}
}

</style>
