<template lang="html">
	<div class="project" ref="projectDiv">
		<div class="project__header">
			<span class="project__tag">
				{{ project.tag.toUpperCase()}}
			</span>
			<span class="project__title">
				{{ project.name }}
			</span>
		</div>
		<div class="project__data">
			<div class="project__info" v-if="project.clients.length">
				<fa-layer>
					<fa-icon
						:icon="['fas', 'user']"
						class="icon-tertiary"
					/>
					<fa-layer-text
						counter
						:value="project.clients.length"
						class="project__infos-value color-blue"
						transform="shrink-6 right-22 down-8"
					/>
				</fa-layer>
			</div>
			<div class="project__info" v-if="project.servers.length">
				<fa-layer >
					<fa-icon
						:icon="['fas', 'server']"
						class="icon-tertiary"
					/>
					<fa-layer-text
						counter
						:value="project.servers.length"
						class="project__infos-value color-blue"
						transform="shrink-6 right-22 down-8"
					/>
				</fa-layer>
			</div>
		</div>
	</div>
</template>

<script>
import { inject, ref, computed, onMounted, reactive } from "vue";
export default {
	props: { project: Object },
	setup(props) {

		const project = ref(props.project);
		const projectDiv = ref(null);
		onMounted(() => {
			projectDiv.value.style.setProperty("--color", project.value.color);
		})

		return {
			project,
			projectDiv
		}
	}
}
</script>

<style lang="scss" scoped>
.project
{
	background-color: $BG_SECONDARY;
	display: flex;
	flex-direction: column;
	gap: 8px;
	box-shadow: $SHD_DEFAULT;
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.1s ease-out;
	border-top: 1px solid rgba(255, 255, 255, 0.05);
	border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	box-sizing: border-box;

	&__header
	{
		display: flex;
		gap: 8px;
		align-items: center;
		user-select: none;

		background-color: $BG_TERTIARY;

		.project__tag {
			font-size: 16px;
			padding: 12px ;
			font-weight: 700;
			display: block;
			// color: var(--color);
			background: var(--color);

		}

		.project__title {
			text-transform: capitalize;
		}
	}

	&:hover
	{
		margin-top: -2px;
		margin-bottom: 2px;
		box-shadow: $SHD_FAR;
	}

	&__data
	{
		padding: 8px 16px 12px 16px;
		display: flex;
		gap: 16px;
		color: $FG_TERTIARY;
		user-select: "none";
	}
}
</style>
