<template lang="html">
	<div class="ui-kebab-menu-title">
		<span class="title">{{title}}</span>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "Title"
		},
	}
}
</script>

<style lang="scss" scoped>

.ui-kebab-menu-title
{
	margin-bottom: 8px;
	user-select: none;

	.title
	{
		font-weight: 700;
		font-size: 0.85rem;
		text-transform: uppercase;
		color: $FG_TERTIARY;
	}
}
</style>
