<template lang="html">
    <div class="ui-checkbox">
        <label :for="id">
            <div class="box" :class="checkboxClasses">
                <fa-icon class="icon" :icon="['fas', 'check']"/>
            </div>
            <p>{{label}}</p>
        </label>
        <input type="checkbox" :id="id" :name="id" @change="updateValue" v-model="value" hidden>
    </div>
</template>

<script>
let id = 0;

export default {
    beforeCreate() {
        this.id = id.toString();
        id += 1;
    },
	name: "Checkbox",
	data () {
		return {
			checkboxClasses : {}
        };
	},
	props: {
		label: {
			type: String,
            default: "Default label"
		},
        type: {
			type: String,
            default: "text"
		},
        value: {
            type: Boolean
        },
        length: {
            type: String,
            default: "default"
        },
        placeholder: {
            type: String
        }
	},
    methods: {
        updateValue (e) {
            console.log(this.value);
            this.$emit("update:value", this.value)

            this.checkboxClasses.checked = this.value;
        }
    },
	mounted () {

        this.checkboxClasses[`length-${this.length}`] = true;
        this.checkboxClasses.checked = this.value;
        // this.checkboxClasses[`size-${this.size}`] = true;
		// this.checkboxClasses[`font-${this.font}`] = true;
	}
}
</script>

<style lang="scss" scoped>

.ui-checkbox
{
    label
    {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-weight: bolder;

        cursor: pointer;
        user-select: none;
        transition: all 0.1s ease;

        padding: 6px 0px;

        &:hover
        {
            color: white;
        }

        .box
        {
            height: 12px;
            width: 12px;
            border-radius: 2px;

            margin-right: 8px;
            background-color: $FG_MAIN;

            transition: all 0.2s ease;

            .icon
            {
                position: relative;
                font-size: 0.65rem;
                top: -4px;
                right: -1px;
                opacity: 0;
                transition: all 0.2s ease;
            }

            &.checked
            {
                background-color: $AC_BLUE;

                .icon
                {
                    opacity: 1;
                }
            }
        }
    }
}
</style>
