<template lang="html">
	<div class="ui-sidebar-folder" :class="sbItemClasses" @click="GoToRoute()">
		<fa-icon fa-fw class="icon" :icon="itemIcon"/>
		<span>{{text || route.name}}</span>
		<div class="spacer"></div>
		<div class="folder-marker">
			<fa-icon icon="caret-right"/>
		</div>

		<div class="folder-contents">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name:"SidebarItem",
	data () {
		return {
			sbItemClasses: {}
		};
	},
	props:{
		text: {
			type: String
		},
		route: {
			type: Object,
			default: {}
		},
		type: {
			type: String,
			default: "default"
		},
		color: {
			type: String,
			default: "default"
		},
		notifications: {
			type: String
		},
		icon: {
			type: String,
			default: "question"
		},
		iconStyle: {
			type: String,
			default: "solid"
		},
	},
	computed: {
		itemIcon () {
			var style;
			switch (this.iconStyle) {
				case "solid":
					style = "fas";
					break;
				case "regular":
					style = "far";
					break;
				case "light":
					style = "fal";
					break;
				default:
					style = "fas";
			}

			return [style, this.icon];
		}
	},
	methods : {
		GoToRoute () {
			this.$router.push(this.route)
		}
	},
	watch: {
		$route () {
			if (this.route.name === this.$route.name) {
				this.sbItemClasses[`active`] = true ;
			}
			else {
				this.sbItemClasses[`active`] = false ;
			}
		}
	},
	mounted () {
		this.sbItemClasses[`color-${this.color}`] = true;
		this.sbItemClasses[`type-${this.type}`] = true;
		if (this.route.name === this.$route.name) {
			this.sbItemClasses[`active`] = true ;
		}
	}
}
</script>

<style lang="scss" scoped>
.ui-sidebar-folder
{
	padding: 8px 32px 8px 16px;
	cursor: pointer;
	display: flex;
	user-select: none;
	position: relative;
	transition: all 0.1s ease;

	&.type-
	{
		&default
		{
			color: $FG_TERTIARY;
			font-weight: 300;
			font-size: 16px;
		}

		&main
		{
			color: $FG_SECONDARY;
			font-weight: 500;
			font-size: 18px;
		}
	}

	&.active
	{
		color: $FG_MAIN;
		background-color: $BG_SECONDARY;


		.icon
		{
			color: $FG_SECONDARY;
		}
	}

	&:hover
	{
		color: $FG_MAIN;
		background-color: $BG_SECONDARY;

		& > .folder-contents
		{
			display: flex;
		}
	}

	.icon
	{
		color: $FG_TERTIARY;
		padding: 0 16px;
		max-width: 16px;
		min-width: 16px;
		
	}
	.spacer
	{
		flex-grow: 1;
	}
	.notifications-count
	{
		align-self: center;

	}

	.folder-contents
	{
		display: none;
		width: 280px;
		position: absolute;
		background-color: $BG_MAIN;
		left: 280px;
		top:-8px;
		padding: 8px 0;
		flex-direction: column;
		box-shadow: $SHD_DEFAULT;
		border-radius: 8px;
		z-index: 0

	}
}
</style>
