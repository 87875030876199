<template lang="html">
	<div
		class="ui-input"
		@click="$refs.input.focus()"
		:class="inputClasses"
		ref="ui-input"
	>
		<fa-icon v-if="!isLoading" class="icon" :icon="icon" />
		<fa-icon
			v-else
			class="loading icon"
			:icon="['far', 'spinner-third']"
			spin
		/>
		<input
			ref="input"
			:placeholder="placeholder"
			type="text"
			name=""
			v-model="value"
			@input="updateValue"
		/>
	</div>
</template>

<script>
export default {
	name: "Input",
	data() {
		return {
			inputClasses: {},
		};
	},
	props: {
		icon: {
			type: [String, Array],
			default: ["far", "search"],
		},
		iconAlign: {
			type: String,
			default: "left",
		},
		value: {
			type: String,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
		},
		length: {
			type: String,
			default: "default",
		},
	},
	methods: {
		updateValue() {
			this.$emit("update:value", this.value);
		},
	},
	mounted() {
		this.inputClasses[`icon-${this.type}`] = true;
		this.inputClasses[`icon-align-${this.iconAlign}`] = true;
		this.inputClasses[`length-${this.length}`] = true;
	},
};
</script>

<style lang="scss" scoped>
.ui-input {
	max-width: 100%;

	display: flex;

	background-color: $BG_SECONDARY;
	border-radius: 8px;
	color: $FG_MAIN;
	padding: 8px 0px;
	font-size: 18px;
	align-items: center;
	cursor: text;
	box-shadow: $SHD_DEFAULT;
	box-sizing: border-box;

	&.icon-align- {
		&right {
			flex-direction: row-reverse;
			padding-left: 8px;
		}

		&left {
			padding-right: 8px;
		}
	}

	&.length {
		&-xs {
			width: 150px;
		}
		&-sm {
			width: 300px;
		}
		&-md {
			width: 600px;
		}
		&-lg {
			width: 900px;
		}
		&-default {
			width: 100%;
		}
	}

	.icon {
		width: 24px;
		padding: 0px 12px;

		&.loading {
			&.fa-spin {
				animation-duration: 0.5s;
			}
		}
	}

	input {
		background-color: transparent;
		border: 0;
		outline: none;
		color: $FG_MAIN;
		width: 100%;

		&::placeholder {
			font-weight: 300;
			font-style: italic;
			font-size: 18px;
		}
	}
}
</style>
