var env = process.env.NODE_ENV;
console.log(env);

if ([
	"prod",
	"preprod",
	"production",
].includes(env)) {
	var config = {
		SKYWAY: {
			URL: "${SKYWAY_URL}",
			PORT: "${SKYWAY_PORT}"
		},
		SERVICE_LIBRARY: {
			URL: "${SERVICE_LIBRARY_URL}",
			PORT: "${SERVICE_LIBRARY_PORT}"
		},
	}

}
else {
	var config = {
		SKYWAY: {
			URL: "https://dev.backoffice.production-webadn.com",
			PORT: "3011"
		},
		SERVICE_LIBRARY: {
			URL: "https://servicelibrary.production-webadn.com",
			PORT: "42420"
			// URL: "http://localhost",
			// PORT: "3010"
		},
	}

}

module.exports = config;
