<template lang="html">
	<div class="ui-card" :class="cardClasses">
		<slot name="default">

		</slot>
	</div>
</template>

<script>
import { inject, onMounted, ref, reactive, computed, watch } from "vue";

export default {
	name:"Card",
	props:{
		color: {
			type: String,
			default: "dark"
		},
		size: {
			type: String,
			default: "medium"
		},
		type: {
			type: String,
			default: "basic"
		}
	},
	setup (props) {

		const cardClasses = ref({})

		onMounted(() => {
			var classes = {}

			classes[`color-${props.color}`] = true
			classes[`size-${props.size}`] = true
			classes[`type-${props.type}`] = true

			cardClasses.value = classes;
		})

		return {
			cardClasses
		}
	}
}
</script>

<style lang="scss" scoped>
	.ui-card
	{
		border-radius: 16px;

		overflow: hidden;

		box-shadow: $SHD_DEFAULT;

		&.type-basic
		{
			padding: 16px;
		}

		&.color-
		{
			&dark
			{
				background-color: $BG_SECONDARY;
				color: $FG_MAIN;
			}
			&light
			{
				background-color: $FG_MAIN;
				color: $BG_SECONDARY;
			}
			&blue
			{
				background-color: $AC_BLUE;
				color: $FG_MAIN;

			}
			&green
			{
				background-color: $AC_GREEN;
				color: $FG_MAIN;
			}
			&yellow
			{
				background-color: $AC_YELLOW;
				color: $BG_TERTIARY;
			}
			&red
			{
				background-color: $AC_RED;
				color: $FG_MAIN;
			}
			&gd-blue
			{
				background: $GD_BLUE;
				color: $FG_MAIN;
			}
			&gd-green
			{
				background: $GD_GREEN;
				color: $FG_MAIN;
			}
			&gd-yellow
			{
				background: $GD_YELLOW;
				color: $FG_MAIN;
			}
			&gd-red
			{
				background: $GD_RED;
				color: $FG_MAIN;
			}


		}

		&.size-
		{
			&xtra-small
			{
				grid-column: span 1;
				grid-row: span 1;
			}
			&small
			{
				grid-column: span 2;
				grid-row: span 1;
			}
			&medium
			{
				grid-column: span 3;
				grid-row: span 2;
			}
			&large
			{
				grid-column: span 5;
				grid-row: span 3;
			}
			&tall
			{
				grid-column: span 3;
				grid-row: span 5;
			}

		}
	}

</style>
