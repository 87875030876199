<template lang="html">
	<div class="ui-kebab-menu-separator">
		<hr class="separator">
	</div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.ui-kebab-menu-separator
{
	margin: 16px -8px;
	.separator
	{
		border-color: $BG_SECONDARY;
	}
}
</style>
