<template lang="html">
	<div class="ui-kebab-menu-item" @click='closeMenu' :class="kebabItemClasses">
		<span class="icon">
			<fa-icon fa-fw class="icon" :icon="icon"/>
		</span>
		<span class="title">
			{{title}}
		</span>
	</div>
</template>

<script>
import { ref, onMounted, watch, inject, getCurrentInstance } from 'vue';

export default {
	name:"KebabMenuItem",
	props: {
		icon:{
			type: [String, Array],
			default: ['fal', 'question']
		},
		title: {
			type: String,
			default: "Title"
		},
		color: {
			type: String,
			default: "default"
		},
		disabled: {
			type: Boolean,
			default: false
		},


	},
	setup(props, {emit, $parent}) {

		const closeMenu = inject("closeMenu")

		const kebabItemClasses = ref({});
		const instance = getCurrentInstance();

		onMounted(() => {
			let classObject = {};
			classObject[`color-${props.color}`] = true;

			kebabItemClasses.value = classObject;
		})

		return {
			kebabItemClasses,
			closeMenu
		};
	}
}
</script>

<style lang="scss" scoped>
.ui-kebab-menu-item
{
	display: flex;
	gap: 8px;
	padding: 8px;
	border-radius: 4px;
	cursor: pointer;
	user-select: none;

	transition: all 0.1s ease;
	margin: 0;

	&.color-
	{
		&red
		{
			color: $AC_RED;
		}
		&blue
		{
			color: $AC_BLUE;
		}
		&green
		{
			color: $AC_GREEN;
		}
		&yellow
		{
			color: $AC_YELLOW
		}
		&faded
		{
			color: $FG_TERTIARY;
		}
	}

	.icon
	{
		width: 22px;
	}

	&:hover
	{
		background-color: transparentize($FG_MAIN, 0.9);
	}
}
</style>
