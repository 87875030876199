<template>
	<div class="page">
		<div class="header">
			<PageHeader title="Clients" />
		</div>

		<div class="main">
			<div class="searchbar">
				<SearchInput
					icon="search"
					icon-align="right"
					placeholder="Rechercher un client ..."
					:isLoading="isSearchbarLoading"
					v-model:value="filterText"
					@input="filterClients"
					id="searchbar"
				/>
				<KebabMenu x-direction="left">
					<!-- <KebabMenuTitle title="Client"/> -->
					<KebabMenuItem icon="plus" title="Nouveau Client" @click="setClientPopUpVisible(true)"/>
				</KebabMenu>
			</div>

			<div class="client-list">
				<div
					class="client"
					role="link"
					tabindex="0"
					v-for="client in filteredClients"
					:key="client"
					@click="goToClient(client._id)"
				>
					<h2 class="client__title">{{ client.name }}</h2>

					<div class="client__infos-list">
						<div class="client__info">
							<fa-layer v-if="client.projects.length" class="client__info-icon fa-lg" >
								<fa-icon
									:icon="['far', 'window-alt']"
									class="icon-tertiary"
								/>
								<fa-layer-text
									counter
									:value="client.projects.length"
									class="client__info-value color-blue"
									transform="shrink-8 right-20 down-8"
								/>
							</fa-layer>
						</div>
					</div>
				</div>
			</div>
		</div>
		<PopUpWrapper :isVisible="newClientFormShown">
			<PopUp :isVisible="newClientFormShown">
				<template v-slot:header>
					<h2>Nouveau client</h2>
				</template>

				<template v-slot:content>
					<FormInput
						v-model:value="clientForm.client.name"
						label="Nom du client"
					/>
				</template>

				<template v-slot:actions>
					<Button color="blue" @click="createNewClient">
						Créer
					</Button>
					<Button type="tertiary" @click="setClientPopUpVisible(false)">
						Annuler
					</Button>
				</template>
			</PopUp>
		</PopUpWrapper>
	</div>
</template>

<script>
import { inject, onMounted, ref, reactive, computed, watch } from "vue";
import { useRouter } from 'vue-router';
import Button from "../../../components/ui/Button.vue";

export default {
	components: { Button },
	name: "Clients",
	props: {},
	setup(props, ctx) {
		const axios = inject("axios");
		const GATEWAY_URL = inject("GATEWAY_URL")
		const router = useRouter();
		const clients = ref([]);
		const filterText = ref("");
		const filteredClients = ref([]);
		const isSearchbarLoading = ref(false);
		const currentTimeout = ref("");
		const newClientFormShown = ref(false);


		const loadClients = async function () {
			try {
				var results = await axios.get(
					`${GATEWAY_URL}/v1/clients`
				);
				clients.value = results.data;
			} catch (e) {
				clients.value = [];
				console.error(e);
			}
		};

		const clientForm = reactive({
			client:{
				name: "",
			}
		});

		const filterClients = async function () {
			if (currentTimeout.value) {
				clearTimeout(currentTimeout.value);
			}
			isSearchbarLoading.value = true;

			currentTimeout.value = setTimeout(async function () {
				const reg = new RegExp(`^.*${filterText.value}.*$`, "i");
				filteredClients.value = clients.value.filter((el) => {
					return reg.test(el.name);
				});
				isSearchbarLoading.value = false;
			}, 300);
		};

		const setClientPopUpVisible = function (isPopUpVisible) {
			newClientFormShown.value = isPopUpVisible;
		};

		const createNewClient = async function() {
			try {
				console.log(`${GATEWAY_URL}/v1/clients`);
				const response = await axios.post(`${GATEWAY_URL}/v1/clients`, {
					client: clientForm.client
				});
			} catch(e) {
				ctx.emit("toast", {
					content: "Une erreur est survenue lors de la création du client",
					color: "gd-red",
					icon: "warning"
				});
			}

			loadClients();
			setClientPopUpVisible(false);
			ctx.emit("toast", {
				content: "Un client a été créé",
				color: "gd-green",
				icon: "check"
			});
		}

		const goToClient = function(clientId) {
			router.push({ path: `/clients/${clientId}`});
		};


		watch(clients, filterClients);

		onMounted(async () => {
			await loadClients();
		});

		return {
			clients,
			filterText,
			filteredClients,
			isSearchbarLoading,
			newClientFormShown,
			clientForm,
			createNewClient,
			loadClients,
			filterClients,
			setClientPopUpVisible,
			goToClient
		};
	},
};
</script>

<style scoped lang="scss">
.main .searchbar {
	display: flex;
	gap: 16px;
	margin-bottom: 32px;

	align-items: center;

	#searchbar {
		flex-grow: 1;
	}
}

.client-list {
	display: grid;
	grid-template-columns: 100%;
	grid-gap: 20px;
}

.client {
	display: grid;
	grid-template-rows: 1fr auto;
	grid-template-columns: 100%;
	grid-gap: 8px;
	min-height: 70px;
	padding: 16px;
	background-color: $BG_SECONDARY;
	border-radius: 8px;
	box-shadow: $SHD_DEFAULT;
	cursor: pointer;

	&__title {
		padding-bottom: 8px;
		font-size: 18px;
		font-weight: 500;
		text-transform: capitalize;
	}

	&__infos-list {
		display: flex;
		flex-flow: row wrap;
		gap: 24px;
		align-self: end;
	}

	&__info {
		flex: 0 0 auto;
		color: $FG_MAIN;
	}

	&__info-icon {
		.color-blue {
			background-color: $AC_BLUE;
		}

		.icon-tertiary {
			color: $FG_TERTIARY;
		}
	}

	&__info-value {
		user-select: none;
	}

	&:hover {
		background-color: $BG_MAIN;
	}

	a {
		color: $FG_SECONDARY;
		text-decoration: none;
	}
}

@media screen and (min-width: 900px) {
	.client-list {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (min-width: 1200px) {
	.client-list {
		grid-template-columns: repeat(3, 1fr);
	}
}
</style>
