<template lang="html">
	<div class="ui ui-color-picker">
		<label for="input-color">{{label}}</label>
		<input id="input-color" type="color" @change="updateColor" :value="inputColor"/>
	</div>
</template>

<script>
import { ref } from 'vue';
export default {
	name: 'InputColor',
	props: {
		value: {
			type: String,
			validator: (value) => {
				return value.match(/^#([0-9A-F]{3}){1,2}$/i);
			}
		},
		label: {
			type: String,
			default: 'Couleur'
		}
	},
	emits: ['change'],
	setup (props, ctx) {
		const inputColor = ref(props.value);
		const label = ref(props.label);
		
		const updateColor = () => {
			inputColor.value = document.getElementById('input-color').value;
			emitUpdateColor();
		};
		const emitUpdateColor = () => {
			ctx.emit('change', inputColor.value);
		};
		return {
			label,
			inputColor,
			updateColor,
		};
	},
}
</script>

<style lang="scss" scoped>
	.ui-color-picker {
		margin-top: 16px;
	}

	label {
		display: block;
		margin-bottom: 4px;
		margin-left: 4px;
		font-size: 0.85em;
		font-weight: 700;
		color: $FG_SECONDARY;
		text-transform: uppercase;
		user-select: none;
	}

	input[type="color"] {
		width: 50px;
		height: 45px;
		padding: 0;
		background-color: $BG_SECONDARY;
		border: 1px solid $BG_TERTIARY;
		border-radius: 8px;
	}

	input[type="color"]::-webkit-color-swatch-wrapper {
		padding: 0;
	}
</style>