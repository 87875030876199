<template lang="html">
	<div class="dd-item" @click="updateDropdownValue">
		<slot name="default">

		</slot>
	</div>
</template>

<script>
import { inject, onMounted, ref, reactive, computed } from "vue";


export default {
	props: {
		value: {
			type: String
		}
	},
	setup(props, context) {

		const updateDropdownValue = function () {
			context.emit("dd-update-value", props.value)
			console.log(props.value);
		}

		return {
			updateDropdownValue
		};
	}
}
</script>

<style lang="scss" scoped>
.dd-item
{
	cursor: pointer;
	padding: 8px;
	border-radius: 4px;

	&:hover
	{
		background: lighten($FG_MAIN, 5%);
	}
}
</style>
